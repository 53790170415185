import React, { useState, useContext, useEffect, useRef, useImperativeHandle, Fragment } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import '../../css/zadatelComponent.css';
import PropTypes from 'prop-types';
import {
    ApplicantMoreModal,
    FormInput,
    ApplicantMoreControls,
    FindAndProcessIco,
    PersonTypeSelector
} from '../index';
import { iconCardText, iconGrid, errorIcon } from '../../assets';
import {
    BASIC_INFO,
    BASIC_INFO_BUSINESS, 
    BASIC_INFO_BUSINESS_APPENDIX, 
    BASIC_INFO_PO,
    ATTORNEY_BASIC_INFO,
    ATTORNEY_BASIC_INFO_BUSINESS,
    ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX,
    ATTORNEY_BASIC_INFO_PO,
    PERSON_AUTHORIZED_DOCUMENTATION, 
    ATTORNEY_PERSON_AUTHORIZED_DOCUMENTATION,
    CONTACTS,
    DELIVERY_LOCATION, 
    LOCATION,
    LOCATION_BUSINESS,
    ATTORNEY_LOCATION,
    ATTORNEY_LOCATION_BUSINESS,
    ATTORNEY_DELIVERY_LOCATION,
    ATTORNEY_CONTACTS,
} from '../../constants/form09.js';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import { shouldNotSave, handlePOAFiles, handlePeople, canResetAttorney, areUserDetailsMatching, spreadObject, validateCisloPopisne } from '../../helperFunctions/helpers';
import { dateValidation } from '../../helperFunctions/validationHelpers.js';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import FormContext from '../../formContexts/FormContext';
import {
    PLNA_MOC_FIRST,
    PLNA_MOC_SECOND,
    APPLICANT_MODEL,
    COUNTRIES,
    PLNA_MOC_FIFTH,
    PLNA_MOC_FOURTH,
    PLNA_MOC_THIRD,
    DEFAULT_DOCUMENTATION_FORMAT,
} from '../../constants/sharedConstants.js';
import { jwtDecode } from 'jwt-decode';
import { handleUpdate } from '../../helperFunctions/modalHelpers.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import {REPRE_ATTORNEY_PERSON_AUTHORIZED, REPRE_PERSON_AUTHORIZED} from '../../constants/zadatelStates.js';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchZamerDetails, isRequestOK } from '../../apiCalls/componentsApiCalls.js';
// import { v4 as uuidv4 } from 'uuid';

const DocumentationZadatel = ({ 
    urlPath,
    foundApplicantIndex,
    setFoundApplicantIndex,
    stage,
    applicantArr,
    setApplicantArr
}) => {
    const { intention, stepValue, validationRef, setStagesArr, stagesArr, saveFormRef, id } = useContext(FormContext); 
    const { versionId } = useParams();
    const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const [ focused, setFocused ] = useState({
        idPowerOfAttorneyExisting: false,
        nationality: false,
        nationality2: false,
        icoNumApp: false,
        icoNumAtt: false,
    });

    const { token } = useContext(AuthContext);
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            id: '',
            applicantMe: true,
            applicantAttorney: false,
            applicantMore: false,
            applicant: APPLICANT_MODEL,
            powerOfAttorney: null,
            attorney: APPLICANT_MODEL,
            applicants: [],  
        }
    });
    const buildIntention = request?.buildIntention;
    const decodedToken = jwtDecode(token);
    const [currentApplicant, setCurrentApplicant] = useState(APPLICANT_MODEL);   
    const prevRequest = usePrevious(request);
    const attorneyAttachmentRef = useRef(null);
    const [zadatelAttachments, setZadatelAttachments] = useState({
        [PLNA_MOC_FIRST]: [],
        [PLNA_MOC_SECOND]: [],
        [PLNA_MOC_THIRD]: [],
        [PLNA_MOC_FOURTH]: [],
        [PLNA_MOC_FIFTH]: [],
    });
    const allZadatelAttachments = React.useMemo(() => Object.values(zadatelAttachments || {})?.flat(), [zadatelAttachments]);
    const firstRender = useFirstRender();
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    // powerOfAttorneyError is only for plnaMocFirst, for other types another check is required
    // const powerOfAttorneyError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments), [request, zadatelAttachments]);
    // const powerOfApplicantInputRepreError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments, 'applicant'), [request, zadatelAttachments]);
    // const powerOfAttorneyInputRepreError = React.useMemo(() => hasPowerOfAttorneyError(request, zadatelAttachments, 'representative'), [request, zadatelAttachments]);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const PARENT_KEY = 'buildIntention';
    const detailsMatching = React.useMemo(() => areUserDetailsMatching(decodedToken, buildIntention), [decodedToken, buildIntention]);
    // TODO refactor
    useEffect(() => {
        const source = axios.CancelToken.source();
        if (id) {
            (async () => {
                if (versionId) {
                    try {
                        const withVersionId = await fetchZamerDetails(versionId, token, undefined, null);
            
                        if (isRequestOK(withVersionId?.status)) {
                            const withVersionIdData = withVersionId?.data?.data;
                            const withVersionIdDataBuildInt = withVersionIdData?.[PARENT_KEY] ?? {};
            
                            const withoutVersionId = await getFormById(id, token, source, intention, null, pathname, navigate);
            
                            if (isRequestOK(withoutVersionId?.status)) {
                                const withoutVersionIdData = withoutVersionId?.data;
                                const withoutVersionIdDataBuildInt = withoutVersionIdData?.[PARENT_KEY] ?? {};
            
                                // OVERRIDE ONLY INSERTED VALUES
                                const combinedBuildIntentionData = {...spreadObject(withVersionIdDataBuildInt), ...spreadObject(withoutVersionIdDataBuildInt), ...DEFAULT_DOCUMENTATION_FORMAT};
                                console.log(combinedBuildIntentionData);
                                    
                                // if (!combinedBuildIntentionData?.id) {
                                //     console.log('id is missing');
                                //     // TODO TEMP for now
                                //     combinedBuildIntentionData.id = uuidv4();
                                // }
        
                                if (combinedBuildIntentionData) {
                                    handlePeople(combinedBuildIntentionData, setRequest, decodedToken, PARENT_KEY);
                                    // handleZadatelPeopleFiles(combinedBuildIntentionData, setZadatelAttachments, setApplicantArr);   
                                }
                            } else {
                                console.log('getFormById failed');
                            }
                        } else {
                            console.log('fetchZamerDetails failed');
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoading(false);
                    }
                } else {
                    try {
                        const response = await getFormById(id, token, source, intention, null, pathname, navigate);
                        if (isRequestOK(response?.status)) {
                            const data = response?.data;
                            const loadedData = data?.[PARENT_KEY];
                            if (loadedData) {
                                handlePeople({...loadedData, ...DEFAULT_DOCUMENTATION_FORMAT}, setRequest, decodedToken, PARENT_KEY);
                            }
                        } else {
                            console.log('getFormById failed');
                        }
                    } catch (error) {
                        console.log(error);
                    } finally {
                        setLoading(false);
                    }
                }
            })();

            return () => {
                source.cancel('Operation canceled by the user.');
            };
        } else {
            console.log('NON EXISTENT ID');
            setLoading(false);
        }
    }, []);
    
    const saveChanges = async () => {
        const source = axios.CancelToken.source();

        if (canResetAttorney(buildIntention)) {
            const updatedRequest = {...request, buildIntention: {...buildIntention, attorney: {...APPLICANT_MODEL, nationality: ''}} };
            await formSave(updatedRequest, token, urlPath, source);
            return;
        }

        formSave(request, token, urlPath, source);
    };

    const handleFileInput = (files, category) => {
        handlePOAFiles(files, category, token, setZadatelAttachments, currentApplicant);
    };

    useEffect(() => {
        setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, applicants: [...applicantArr] } }));
    }, [applicantArr]);

    useEffect(() => {
        if (foundApplicantIndex === null || stage !== 2) {
            return;
        }

        if (foundApplicantIndex === -1) {
            setTimeout(() => {
                window.scrollTo({
                    top: Number(window.scrollY || document.documentElement.scrollTop || 0) + Number(attorneyAttachmentRef?.current?.getBoundingClientRect()?.top || 0) - 150,  
                    behavior: 'instant'
                });
            }, 150);
            setFoundApplicantIndex(null);
            return;
        }

        const foundApplicant = applicantArr[foundApplicantIndex];
        if (!foundApplicant?.id) {
            return;
        }

        setTimeout(() => {
            handleUpdate(foundApplicant.id, applicantArr, setCurrentApplicant, setShow);
            setFoundApplicantIndex(null);
        }, 250);

    }, [foundApplicantIndex, stage]);

    useEffect(() => {
        // avoid multiple save for same object
        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }

        saveChanges();
    }, [request.buildIntention]);

    const handleFocused = (elementToFocus) => {
        setFocused(prev => ({...prev, [elementToFocus]: true}));
    };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
            let warning = false;

            if (!request.buildIntention.applicantMore)
            {
                if(request.buildIntention.applicant.isFO)
                {
                    if (!buildIntention?.applicant || ['names', 'lastName', 'dateOfBirth'].some(key => 
                        !buildIntention.applicant[key] || buildIntention.applicant[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.applicant?.address || ['city', 'zip'].some(key => 
                        !buildIntention.applicant.address[key] || buildIntention.applicant.address[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.applicant?.shippingSameAsPr &&
                        (!buildIntention?.applicant?.shippingAddress || ['city', 'zip'].some(key => 
                            !buildIntention.applicant.shippingAddress[key] || buildIntention.applicant.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                }
                else if (request.buildIntention.applicant.isFOBusiness)
                {
                    if (!buildIntention?.applicant || ['ico', 'names', 'lastName', 'dateOfBirth'].some(key => 
                        !buildIntention.applicant[key] || buildIntention.applicant[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!buildIntention?.applicant?.address || ['city', 'zip'].some(key => 
                        !buildIntention.applicant.address[key] || buildIntention.applicant.address[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!buildIntention?.applicant?.shippingSameAsPr &&
                        (!buildIntention?.applicant?.shippingAddress || ['city', 'zip'].some(key => 
                            !buildIntention.applicant.shippingAddress[key] || buildIntention.applicant.shippingAddress[key].trim() === '')))
                    {
                        err = true;
                    }
                }
                else if (request.buildIntention.applicant.isPO)
                {
                    if (!buildIntention?.applicant || ['ico', 'poName', 'names', 'lastName', 'dateOfBirth', 'mandate', 'mandateFrom'].some(key => 
                        !buildIntention.applicant[key] || buildIntention.applicant[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.applicant?.address || ['city', 'zip'].some(key => 
                        !buildIntention.applicant.address[key] || buildIntention.applicant.address[key].trim() === '')) {
                        err = true;
                    }
                }
            }
            
            if (!request.buildIntention.applicantMe)
            {
                if (request.buildIntention.attorney.isFO) {
                    if (!buildIntention?.attorney || ['names', 'lastName', 'dateOfBirth'].some(key => 
                        !buildIntention.attorney[key] || buildIntention.attorney[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.attorney?.address || ['city', 'zip'].some(key => 
                        !buildIntention.attorney.address[key] || buildIntention.attorney.address[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.attorney?.shippingSameAsPr &&
                        (!buildIntention?.attorney?.shippingAddress || ['city', 'zip'].some(key => 
                            !buildIntention.attorney.shippingAddress[key] || buildIntention.attorney.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                } else if (request.buildIntention.attorney.isFOBusiness) {
                    if (!buildIntention?.attorney || ['ico', 'names', 'lastName', 'dateOfBirth'].some(key => 
                        !buildIntention.attorney[key] || buildIntention.attorney[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.attorney?.address || ['city', 'zip'].some(key => 
                        !buildIntention.attorney.address[key] || buildIntention.attorney.address[key].trim() === '')) {
                        err = true;
                    } else if (!buildIntention?.attorney?.shippingSameAsPr &&
                        (!buildIntention?.attorney?.shippingAddress || ['city', 'zip'].some(key => 
                            !buildIntention.attorney.shippingAddress[key] || buildIntention.attorney.shippingAddress[key].trim() === ''))) {
                        err = true;
                    }
                } else if (request.buildIntention.attorney.isPO) {
                    if (!buildIntention?.attorney || ['ico', 'poName', 'names', 'lastName', 'dateOfBirth', 'mandate', 'mandateFrom'].some(key => 
                        !buildIntention.attorney[key] || buildIntention.attorney[key].trim() === ''))
                    {
                        err = true;
                    }
                    else if (!buildIntention?.attorney?.address || ['city', 'zip'].some(key => 
                        !buildIntention.attorney.address[key] || buildIntention.attorney.address[key].trim() === ''))
                    {
                        err = true;
                    }
                }
            } 

            if (dateValidation(buildIntention.applicant.dateOfBirth) || dateValidation(buildIntention.attorney.dateOfBirth)) {
                err = true;
            }

            // if (powerOfAttorneyError || powerOfApplicantInputRepreError || powerOfAttorneyInputRepreError) {
            //     err = true; 
            // }

            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/;
            const telPattern = /^\+?(?:\(\d+\))?[\d ()-]{9,}$/;
            const icoPattern = /^[0-9]{8}$/;

            if (buildIntention?.applicant?.address?.zip)
            {
                const cleanedValue = buildIntention?.applicant?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            if (buildIntention?.attorney?.address?.zip)
            {
                const cleanedValue = buildIntention?.attorney?.address?.zip?.replace(/\s+/g, '');
                warning = (isNaN(cleanedValue) || cleanedValue.length !== 5);
            }
            if (buildIntention?.applicant?.email)
            {
                warning = !emailPattern.test(buildIntention?.applicant?.email);
            }
            if (buildIntention?.attorney?.email)
            {
                warning = !emailPattern.test(buildIntention?.attorney?.email);
            }
            if (buildIntention?.applicant?.phone )
            {
                const cleanedValue = buildIntention?.applicant?.phone?.replace(/\s+/g, '');
                warning = (!telPattern.test(cleanedValue) || cleanedValue.length < 9);
            }
            if (buildIntention?.attorney?.phone)
            {
                const cleanedValue = buildIntention?.attorney?.phone?.replace(/\s+/g, '');
                warning = (!telPattern.test(cleanedValue) || cleanedValue.length < 9);
            }
            if (buildIntention?.applicant?.ico)
            {   
                warning = !icoPattern.test(buildIntention?.applicant?.ico?.trim().replace(/\s+/g, ''));
            }
            if (buildIntention?.attorney?.ico)
            {
                warning = !icoPattern.test(buildIntention?.attorney?.ico?.trim().replace(/\s+/g, ''));
            }
            if (buildIntention?.applicantMe && buildIntention?.applicant?.isFO) {
                warning = !detailsMatching;
            }
            if(!detailsMatching){
                err = true;
            }

            if (buildIntention?.applicant?.address?.descNum)
                err = validateCisloPopisne(buildIntention?.applicant?.address?.descNum, false);

            if (buildIntention?.attorney?.address?.descNum)
                err = validateCisloPopisne(buildIntention?.applicant?.address?.descNum, false);

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, warning};
                }
                return stg;
            }));
    
            return !err;
        }
    }));

    return (
        <>
            {loading ? <div>Načítám formulář...</div> :
                <Container fluid>
                    {/* TITLE */}
                    <Row className='row-wrapper'>
                        <h2 className='p-0 m-0 main-title'>{`${stepValue}. Žadatel (stavebník)`}</h2>
                    </Row>

                    {/* ŽADATEL */}
                    <PersonTypeSelector
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'main'}
                        setZadatelAttachments={setZadatelAttachments}
                        setCurrentApplicant={setCurrentApplicant}
                        setApplicantArr={setApplicantArr}
                        parentKey={PARENT_KEY}
                    />

                    <h3 className='p-0 id-title'>{!request.buildIntention.applicantMore ? 'Identifikační údaje žadatele' : 'Identifikační údaje žadatelů'}</h3>

                    {/* IDENTIFIKAČNÍ ÚDAJE ŽADATELE */}
                    {!request.buildIntention.applicantMore &&
                    <PersonTypeSelector 
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'applicant'}
                        setZadatelAttachments={setZadatelAttachments}
                        parentKey={PARENT_KEY}
                    />}


                    {/* ZÁKLADNÍ ÚDAJE */}
                    {!request.buildIntention.applicantMore && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                request.buildIntention.applicant.isFO &&
                                BASIC_INFO.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.buildIntention.applicant.isFOBusiness &&
                                BASIC_INFO_BUSINESS.map(item => ({...item, object: PARENT_KEY})).map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco parentProp={'buildIntention'} info={item.info} updateProp={'applicant'} focusKey={'icoNumApp'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {
                                request.buildIntention.applicant.isPO &&
                                BASIC_INFO_PO.map(item => ({...item, object: PARENT_KEY})).map(item =>  {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco parentProp={'buildIntention'} info={item.info} updateProp={'applicant'} focusKey={'icoNumApp'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {!request.buildIntention.applicant.isPO && <label className='d-flex flex-column label' style={{ marginTop: '1.5rem', color: (!buildIntention?.applicant?.nationality?.trim() && (error || focused.nationality))  ? '#C52A3A' : '' }}>
                                Státní příslušnost
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <select type='select'
                                                value={buildIntention?.applicant?.nationality ?? 'CZE'}
                                                required
                                                style={{ border: error && !buildIntention?.applicant?.nationality?.trim() && 'solid 1px #C52A3A' }}
                                                {...(focused.nationality && { focused: 'true' })}
                                                onChange={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, applicant: { ...state.buildIntention.applicant, nationality: e.target.value } } }));
                                                }}
                                                onBlur={() => {
                                                    handleFocused('nationality');
                                                }}
                                                className='id-input2 dropdown-input'
                                            >
                                                <option value={''}>Vyberte</option>
                                                {COUNTRIES.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}     
                                            </select>
                                        </Col>
                                    </Row>
                                </Container>
                            </label>}
                            {
                                request.buildIntention.applicant.isFOBusiness &&
                                BASIC_INFO_BUSINESS_APPENDIX.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* TRVALÝ POBYT / MÍSTO PODNIKÁNÍ */}
                    {!request.buildIntention.applicantMore && 
                    <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>{request.buildIntention.applicant.isFO ? 'Trvalý pobyt' : request.buildIntention.applicant.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                        <form className='p-0'>
                            { 
                                (request.buildIntention.applicant.isPO || request.buildIntention.applicant.isFO) &&
                                LOCATION.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            { 
                                request.buildIntention.applicant.isFOBusiness &&
                                LOCATION_BUSINESS.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA STEJNÁ */}
                    {(!request.buildIntention.applicantMore && !request.buildIntention.applicant.isPO) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.buildIntention.applicant.shippingSameAsPr}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, applicant: { ...state.buildIntention.applicant, shippingSameAsPr: e.target.checked } } })); }} 
                                />
                                Doručovací adresa je stejná jako adresa {request.buildIntention.applicant.isFO ? 'trvalého pobytu' : 'místa podnikání'}
                            </label>
                        </form>
                    </Row>)}
                    {!detailsMatching && request?.buildIntention?.applicant?.isFO && request?.buildIntention?.applicantMe && <Row className='pb-4'>
                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' className='me-2 pt-1 align-self-start' />
                            Přihlášený uživatel není uveden jako žadatel. Prosím zkontrolujte údaje, k autorizaci je nutné, aby dokumentaci odeslala oprávněná osoba. Vkládáte-li dokumentaci za někoho jiného, vyberte prosím výše, že žadatel je zastoupen vámi.
                        </Container>
                    </Row>}
                    {!detailsMatching && !(request?.buildIntention?.applicant?.isFO && request?.buildIntention?.applicantMe) && <Row className='pb-4'>
                        <Container className='mt-2' style={{color: '#C52A3A', fontSize: '0.751rem'}}>
                            <img src={errorIcon} alt='error icon' className='me-2 pt-1 align-self-start' />
                            Přihlášený uživatel není uveden jako žadatel nebo zástupce žadatele, případně osoba oprávněná jednat jménem žadatele. Prosím zkontrolujte zadané údaje, k autorizaci je nutné, aby dokumentaci vložila oprávněná osoba.
                        </Container>
                    </Row>}


                    {/* DORUČOVACÍ ADRESA */}
                    {(!request.buildIntention.applicantMore && !request.buildIntention.applicant.shippingSameAsPr && !request.buildIntention.applicant.isPO) && (<Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Doručovací adresa</h3>
                        <form className='p-0'>
                            {
                                DELIVERY_LOCATION.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>)}

                    {/* KONTAKTNÍ ÚDAJE */}
                    {!request.buildIntention.applicantMore && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                CONTACTS.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.buildIntention.applicant.isFOBusiness) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.buildIntention.applicant.includeRepresentative}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, applicant: { ...state.buildIntention.applicant, includeRepresentative: e.target.checked } } })); }}
                                />
                                Zastoupena jinou osobou
                            </label>
                        </form>
                    </Row>)}

                    {/* POVĚŘENÁ OSOBA */}

                    {request.buildIntention.applicant.isPO && !request.buildIntention.applicantMore && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                        <form className='p-0'>
                            {
                                PERSON_AUTHORIZED_DOCUMENTATION.filter(item => !(item.marker && item.marker === 'FOP')).map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}
                    {((request.buildIntention.applicant.isFOBusiness && request.buildIntention.applicant.includeRepresentative)) && !request.buildIntention.applicantMore && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Osoba zastupující fyzickou osobu podnikající</h3>
                        <form className='p-0'>
                            {
                                REPRE_PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'PO')).map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {request.buildIntention.applicantMore && 
                    <ApplicantMoreControls
                        applicantArr={applicantArr}
                        iconCardText={iconCardText}
                        iconGrid={iconGrid}
                        setCurrentApplicant={setCurrentApplicant}
                        setZadatelAttachments={setZadatelAttachments}
                        setShow={setShow}
                        setApplicantArr={setApplicantArr}
                    />}

                    {!request.buildIntention.applicantMe && <h3 className='p-0 id-title'>Zástupce žadatele</h3>}

                    {/* ZÁSTUPCE ŽADATELE */}
                    {!request.buildIntention.applicantMe &&
                    <PersonTypeSelector 
                        request={request} 
                        setRequest={setRequest} 
                        decodedToken={decodedToken}
                        keyToUpdate={'attorney'}
                        setZadatelAttachments={setZadatelAttachments}
                        parentKey={PARENT_KEY}
                    />}

                    {/* ZÁKLADNÍ ÚDAJE ZÁSTUPCE */}
                    {!request.buildIntention.applicantMe && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                request.buildIntention.attorney.isFO &&
                                ATTORNEY_BASIC_INFO.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.buildIntention.attorney.isFOBusiness &&
                                ATTORNEY_BASIC_INFO_BUSINESS.map(item => ({...item, object: PARENT_KEY})).map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco parentProp={'buildIntention'} info={item.info} updateProp={'attorney'} focusKey={'icoNumAtt'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest}  />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {
                                request.buildIntention.attorney.isPO &&
                                ATTORNEY_BASIC_INFO_PO.map(item => ({...item, object: PARENT_KEY})).map(item => {
                                    if (item?.state === 'ico') {
                                        return (<Fragment key={`${item.id}-${item.refer}`}>
                                            <FindAndProcessIco parentProp={'buildIntention'} info={item.info} updateProp={'attorney'} focusKey={'icoNumAtt'} handleFocused={handleFocused} focused={focused} request={request} setRequest={setRequest} />
                                        </Fragment>);
                                    }
                                    return <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />;
                                })
                            }
                            {!request.buildIntention.attorney.isPO && <label className='d-flex flex-column label' style={{ marginTop: '1.5rem', color: (!buildIntention?.attorney?.nationality?.trim() && (error || focused.nationality2))  ? '#C52A3A' : '' }}>
                                Státní příslušnost
                                <Container fluid>
                                    <Row>
                                        <Col xs={12} md={6}>
                                            <select
                                                type='select' 
                                                value={buildIntention?.attorney?.nationality ?? 'CZE'}
                                                required
                                                {...(focused.nationality2 && {focused: 'true'})}
                                                style={{ border: error && !buildIntention?.attorney?.nationality?.trim() && 'solid 1px #C52A3A' }}
                                                onChange={(e) => {
                                                    setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, attorney: { ...state.buildIntention.attorney, nationality: e.target.value } } }));
                                                }}
                                                onBlur={() => {
                                                    // setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, applicant: { ...state.buildIntention.applicant, nationality: e.target.value } } }));
                                                    handleFocused('nationality2');
                                                }}
                                                className='id-input2 dropdown-input' >
                                                <option value={''}>Vyberte</option>
                                                {COUNTRIES.map((country) => (
                                                    <option key={country.value} value={country.value}>{country.label}</option>
                                                ))}     
                                            </select>
                                        </Col>
                                    </Row>
                                </Container>
                            </label>}
                            {
                                request.buildIntention.attorney.isFOBusiness &&
                                ATTORNEY_BASIC_INFO_BUSINESS_APPENDIX.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* TRVALÝ POBYT ZÁSTUPCE / MÍSTO PODNIKÁNÍ ZÁSTUPCE */}
                    {!request.buildIntention.applicantMe && 
                    <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>{request.buildIntention.attorney.isFO ? 'Trvalý pobyt' : request.buildIntention.attorney.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
                        <form className='p-0'>
                            {
                                (request.buildIntention.attorney.isPO || request.buildIntention.attorney.isFO) &&
                                ATTORNEY_LOCATION.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                            {
                                request.buildIntention.attorney.isFOBusiness &&
                                ATTORNEY_LOCATION_BUSINESS.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA ZÁSTUPCE STEJNÁ */}
                    {(!request.buildIntention.applicantMe && !request.buildIntention.attorney.isPO) && <Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={request.buildIntention.attorney.shippingSameAsPr} 
                                    onChange={(e) => { setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, attorney: { ...state.buildIntention.attorney, shippingSameAsPr: e.target.checked } } })); }} 
                                />
                                Doručovací adresa je stejná jako adresa {request.buildIntention.attorney.isFO ? 'trvalého pobytu' : 'místa podnikání'}
                            </label>
                        </form>
                    </Row>}

                    {/* DORUČOVACÍ ADRESA ZÁSTUPCE */}
                    {(!request.buildIntention.applicantMe && !request.buildIntention.attorney.isPO && !request.buildIntention.attorney.shippingSameAsPr) && <Row className='border p-4 row-wrapper'>
                        <h3 className='p-0 id-title'>Doručovací adresa</h3>
                        <form className='p-0'>
                            {
                                ATTORNEY_DELIVERY_LOCATION.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* KONTAKTNÍ ÚDAJE ZÁSTUPCE */}
                    {!request.buildIntention.applicantMe && <Row className='row-wrapper'>
                        <form className='p-0'>
                            {
                                ATTORNEY_CONTACTS.map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.buildIntention.attorney.isFOBusiness) && (<Row className='row-wrapper'>
                        <form className='p-0'>
                            <label className='id-checkbox-label'>
                                <input type='checkbox' className='id-checkbox-input' checked={buildIntention.attorney.includeRepresentative}
                                    onChange={(e) => {  setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, attorney: { ...state.buildIntention.attorney, includeRepresentative: e.target.checked } } })); }}
                                />
                                Zastoupena jinou osobou
                            </label>
                        </form>
                    </Row>)}

                    {(request.buildIntention.attorney.isPO) && !request.buildIntention.applicantMe && <Row className='border p-4'>
                        <h3 className='p-0 id-title'>Osoba oprávněná jednat jménem právnické osoby</h3>
                        <form className='p-0'>
                            {
                                ATTORNEY_PERSON_AUTHORIZED_DOCUMENTATION.filter(item => !(item.marker && item.marker === 'FOP')).map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {(request.buildIntention.attorney.includeRepresentative && request.buildIntention.attorney.isFOBusiness) && !request.buildIntention.applicantMe && <Row className='border p-4'>
                        <h3 className='p-0 id-title'>Osoba zastupující fyzickou osobu podnikající</h3>
                        <form className='p-0'>
                            {
                                REPRE_ATTORNEY_PERSON_AUTHORIZED.filter(item => !(item.marker && item.marker === 'PO')).map(item => ({...item, object: PARENT_KEY})).map(item => (
                                    <FormInput key={`${item.id}-${item.refer}`} {...item} request={request} setRequest={setRequest} />
                                ))
                            }
                        </form>
                    </Row>}

                    {/* MODAL WINDOW */}
                    <ApplicantMoreModal
                        zadatelAttachments={allZadatelAttachments}
                        show={show}
                        setShow={setShow}
                        currentApplicant={currentApplicant}
                        setCurrentApplicant={setCurrentApplicant}
                        applicantArr={applicantArr}
                        setApplicantArr={setApplicantArr}
                        plnaMocForZadatelAttachment={zadatelAttachments[PLNA_MOC_SECOND]}
                        plnaMocForZadatelRepreAttachment={zadatelAttachments[PLNA_MOC_FIFTH]}
                        setZadatelAttachments={setZadatelAttachments}
                        handleFileInput={handleFileInput}
                        isDocumentation={true}
                    />
                </Container>
            }
        </>
    );
};

DocumentationZadatel.propTypes = {
    urlPath: PropTypes.string.isRequired,
    foundApplicantIndex: PropTypes.number,
    setFoundApplicantIndex: PropTypes.func,
    stage: PropTypes.number,
    applicantArr: PropTypes.arrayOf(Object),
    setApplicantArr: PropTypes.func,
};

export default DocumentationZadatel;
