import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { findChosenIntention, handleExistingConditionals, intentionsSort, resetIntentionWithZamerType, spreadObject, spreadParcelsAndConstructions } from '../helperFunctions/helpers';
import { isRequestOK, fetchZamerDetails, fetchZamery } from '../apiCalls/componentsApiCalls';
import { AuthContext } from 'react-oauth2-code-pkce';
import usePrevious from './CustomHooks/usePrevious';
import { errorIcon, infoCircle } from '../assets';
import { POST_BODY_FOR_FILTER } from '../constants/sharedConstants';
import FormContext from '../formContexts/FormContext';
import { checkZamerStrictName, validateZamerName } from '../helperFunctions/validationHelpers';
import useDebounce from '../components/CustomHooks/useDebounce';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const ZamerSelectionControls = ({
    title = 'Název záměru',
    textExistujici = 'existující záměr',
    textNovy = 'nový záměr',
    request,
    setRequest,
    state,
    setState,
    buildIntentionModel,
    isIntentionCheckboxDisabled = false,
    setParcelAndConstructionData,
    parcelAndConstructionData = {}, // default to {} for error handling
    setNewBuilding,
    isDocumentationWithId = false,
    documents = null,
    setDocuments = null,
    documentationVersion = false,
}) => {
    const { token } = useContext(AuthContext);
    const { stepValue, stagesArr, intention, id, setUploadError, setUploadedBytes } = useContext(FormContext);
    const { inputValue, zamerType } = state || {};
    const prevZamerType = usePrevious(state?.zamerType || '');
    const [isLoading, setIsLoading] = useState(true);
    const [zameryList, setZameryList] = useState([]);
    const existsIntention = useMemo(() => !!zameryList.length, [zameryList]);
    const error = [...(stagesArr || [])].find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    const isEmpty = useMemo(() => !request?.buildIntention?.title?.value?.trim(), [request?.buildIntention]);
    const isLimitExceeded = useMemo(() => request?.buildIntention?.title?.value?.trim()?.length > 100, [request?.buildIntention]);
    const isValidName = useMemo(() => zameryList?.some(item => item === inputValue), [zameryList, inputValue]);
    const [focused, setFocused] = useState(false);
    const hasError = ((error && (isEmpty || isLimitExceeded || isValidName)) || (focused && (isEmpty || isLimitExceeded || isValidName)));
    const limit = 100;
    const debouncedIntention = useDebounce(inputValue);
    const location = useLocation();
    const isCopied = location?.state?.isCopied ?? false;
    const existingZamerIsSet = useMemo(() => zamerType === 'existing', [zamerType]);
    const decodedToken = jwtDecode(token);
    const shouldNotFetchExistingIntentions = useMemo(() => Boolean(isCopied || isDocumentationWithId || isIntentionCheckboxDisabled || documentationVersion), [isCopied, isDocumentationWithId, isIntentionCheckboxDisabled, documentationVersion]);
    const { canInsertApprovedConstructions, isForm10, isForm15, isForm05 } = handleExistingConditionals(intention);
    const dynamicBuildConstructionsKey = canInsertApprovedConstructions ? 'approvedConstructions' : 'buildConstructions';

    const handleAddedIntention = async (input, signal) => {
        const chosenIntention = findChosenIntention(input, zameryList);
        if (!chosenIntention) {
            return;
        }
    
        setIsLoading(true);
    
        try {
            const chosenZadost = zameryList?.find(el => el.projectId === chosenIntention.projectId);
            if (!chosenZadost) {
                return;
            }
    
            const response = await fetchZamerDetails(chosenZadost?.versionId, token, signal, setUploadError);
            if (signal?.aborted || !isRequestOK(response?.status)) {
                return;
            }
    
            const buildIntention = response?.data?.data?.buildIntention || {};
            if (!Object.keys(buildIntention).length) {
                return;
            }
    
            const applicantsDetailsToInsert = [
                ...(buildIntention?.applicants || []),
                buildIntention?.attorney,
                buildIntention?.applicant,
            ].filter(Boolean);
    
            const applicantData = buildIntention?.applicant || {};
            const attorneyData = buildIntention?.attorney || {};
            const includeRepresentative = applicantData?.repreNames && applicantData?.repreLastName && applicantData?.repreDateOfBirth;
    
            const formToUpdate = applicantsDetailsToInsert.reduce((acc, el) => {
                if (el?.personRelation === 'APPLICANT') {
                    acc.applicant = {
                        ...el,
                        nationality: applicantData?.nationality === '203' ? 'CZE' : applicantData?.nationality,
                        includeRepresentative: applicantData?.includeRepresentative || includeRepresentative,
                        repreNationality: applicantData?.repreNationality === '203' ? 'CZE' : applicantData?.repreNationality,
                    };
                } else if (el?.personRelation === 'ATTORNEY') {
                    acc.attorney = {
                        ...el,
                        nationality: attorneyData?.nationality === '203' ? 'CZE' : attorneyData?.nationality,
                        repreNationality: attorneyData?.repreNationality === '203' ? 'CZE' : attorneyData?.repreNationality,
                    };
                } else if (el?.personRelation === 'APPLICANTS') {
                    acc.applicants = [...(acc?.applicants || []), { ...el }];
                }
                return acc;
            }, {});
            // on existing zamer load spread constructions and parcels dynamically on FE and serialize them with uuid4()
            spreadParcelsAndConstructions(
                buildIntention, //obj
                setParcelAndConstructionData, //func
                parcelAndConstructionData, // obj
                isForm10, // bool as 'isForm10ZamerLoad'
            );

            setRequest(state => ({
                ...state,
                overrideCleanup: true,
                form: {
                    ...state.form,
                    ...formToUpdate,
                    ...(isForm15 && {
                        ...(buildIntention?.buildConstructions?.length > 0 ? { removeBuilding: true } : {}),
                        ...(buildIntention?.buildParcels?.length > 0 ? { removeTerrain: true } : {})
                    }),
                    applicantAttorney: buildIntention?.applicantAttorney ?? false,
                    applicantMe: buildIntention?.applicantMe ?? true,
                    applicantMore: buildIntention?.applicantMore ?? false,
                },
                [dynamicBuildConstructionsKey]: [...(buildIntention?.buildConstructions || [])], // for 10, 11, 12, 13, 14 map to approvedConstructions else buildConstructions
                buildParcels: [...(buildIntention?.buildParcels || [])],
                affectedBuildConstructions: [...(buildIntention?.affectedBuildConstructions || [])],
                affectedBuildParcels: [...(buildIntention?.affectedBuildParcels || [])],
                // set buildIntention at last which triggers save in parent component
                buildIntention: {
                    ...state.buildIntention,
                    ...(spreadObject(buildIntention) || {}),
                    newBuilding: false,
                    changeOfFinishedBuilding: false,
                    buildingTemporary: false,
                    setOfBuildings: false,
                    techOrMarketFacility: false,
                    siteFacility: false,
                    waterworks: false,
                    ...(buildIntention?.affectedBuildConstructions?.length > 0 || buildIntention?.affectedBuildParcels?.length > 0 ? { useNeighborFacility: true } : {}),
                }
            }));
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
            setTimeout(() => {
                setRequest(prev => {
                    // eslint-disable-next-line no-unused-vars
                    const { overrideCleanup, ...rest } = prev || {};
                    return rest;
                });
            }, 0);
        }
    };
    
    const fetchZameryList = async (signal) => {
        try {
            const response = await fetchZamery(POST_BODY_FOR_FILTER, token, signal, null, false);

            if (!signal.aborted && isRequestOK(response.status)) {
                setZameryList([...(response?.data?.data || [])].sort(intentionsSort));
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };
    
    useEffect(() => {
        if (shouldNotFetchExistingIntentions) {
            setIsLoading(false);
            return;
        }
        const controller = new AbortController();
        const signal = controller.signal;

        fetchZameryList(signal);
    
        return () => {
            setFocused(false);
            controller.abort();
        };
    }, []);
    
    const handleIntentionChange = (e) => {
        validateZamerName(e.target);
        setState(prevState => ({ ...prevState, inputValue: e.target.value }));
    };
    
    useEffect(() => {
        if (!existsIntention) {
            return;
        }
    
        let controller;
        if (existingZamerIsSet && debouncedIntention) {
            controller = new AbortController();
            const signal = controller.signal;
            handleAddedIntention(debouncedIntention, signal);
        }
    
        return () => {
            try {
                if (controller instanceof Object) controller?.abort();
            } catch (e) {
                console.log(e);
            }
        };
    }, [debouncedIntention, existingZamerIsSet, existsIntention]);
    
    return (
        <>
            {!isDocumentationWithId && <Row className='mb-4'>
                <Container className='d-flex flex-column p-0'>
                    <Row>
                        <Col xs={12} md={6}>
                            <label className={(isIntentionCheckboxDisabled || (!existsIntention && !isLoading)) ? 'd-flex align-items-center mb-2 label disabled' : 'd-flex align-items-center mb-2 label'} style={{ cursor: existsIntention ? 'pointer' : '', width: 'fit-content' }}>
                                <input
                                    type="radio"
                                    name="zamer"
                                    value="existing"
                                    disabled={(isIntentionCheckboxDisabled || (!existsIntention && !isLoading))}
                                    className="radio"
                                    checked={zamerType === 'existing'}
                                    onChange={(e) => {
                                        resetIntentionWithZamerType(
                                            e.target.value,
                                            setState,
                                            setRequest,
                                            buildIntentionModel,
                                            request.buildIntention.title?.value ?? '',
                                            null,
                                            null,
                                            null,
                                            null,
                                            null,
                                            null,
                                            null,
                                            null
                                        );
                                    }}
                                />
                                {textExistujici}
                            </label>
                            <label className='d-flex align-items-center label' style={{ cursor: 'pointer', width: 'fit-content' }}>
                                <input
                                    type='radio'
                                    name='zamer'
                                    value='new'
                                    className='radio'
                                    disabled={isIntentionCheckboxDisabled}
                                    checked={zamerType === 'new'}
                                    onChange={(e) => {
                                        resetIntentionWithZamerType(
                                            e.target.value,
                                            setState,
                                            setRequest,
                                            buildIntentionModel,
                                            prevZamerType,
                                            request.buildIntention?.id ?? id,
                                            token,
                                            setParcelAndConstructionData ?? null,
                                            setNewBuilding ?? null,
                                            decodedToken,
                                            documents,
                                            setDocuments,
                                            setUploadedBytes
                                        );
                                    }}
                                />
                                {textNovy}
                            </label>
                            {(!shouldNotFetchExistingIntentions && !existsIntention && !isLoading) && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-1 kontrola-info'>
                                <img src={infoCircle} alt="infoCircle" className='me-2' />Ve vašem účtu jsme nenalezli žádný záměr, prosím vytvořte nový.
                            </Col>}
                        </Col>
                        {isForm05 && (
                            <Col xs={12} md={6} className='d-flex flex-column justify-content-center gap-1 mt-3 mt-md-0'>
                                <p className='input-help-text'>Uveďte název záměru uvedený ve vydaném povolení.</p>
                                <p className='input-help-text'>Nenajdete-li záměr, vytvořte prosím nový.</p>
                            </Col>
                        )}
                    </Row>
                </Container>
            </Row>}

            <Container className='p-0'>
                {zamerType === 'existing' &&
                    <Row className='row-wrapper'>
                        <p className='mb-2 p-0 title' style={{ color: error && inputValue?.trim() === '' ? '#C52A3A' : 'black' }}>{isDocumentationWithId ? 'Číslo a název záměru' : 'Vyhledat záměr'}</p>
                        <Row className='d-flex align-items-center'>
                            <Col xs={12} md={6} className='d-flex flex-column mb-3 mb-md-0'>
                                <input
                                    type='text'
                                    list='zamery'
                                    disabled={(isIntentionCheckboxDisabled || isDocumentationWithId)}
                                    value={inputValue ?? ''}
                                    style={{ border: error && inputValue?.trim() === '' && 'solid 1px #C52A3A' }}
                                    onChange={handleIntentionChange}
                                    onBlur={(e) => !checkZamerStrictName(e.target) ? resetIntentionWithZamerType('existing', setState, setRequest, buildIntentionModel, request.buildIntention.title?.value ?? '', null, null, null, null, null, null, null, null) : ''}
                                    className={`id-input2 ${(isIntentionCheckboxDisabled || isDocumentationWithId) ? '' : 'bg-white'} ${!isDocumentationWithId ? (isLoading ? 'with-loading-and-search' : 'with-search') : ''}`}
                                    placeholder='Dle ID či názvu'
                                />
                                <datalist id='zamery'>
                                    {zameryList.map((zamer, idx) => {
                                        return <option key={zamer.id ?? idx}>{(zamer.projectId || zamer.projectName) ? `${zamer.projectId ?? ''} ${(zamer.projectId && zamer.projectName) ? '-' : ''} ${zamer.projectName ?? ''}` : ''}</option>;
                                    })}
                                </datalist>
                            </Col>
                            {!isDocumentationWithId && <Col xs={12} md={6} className='input-help-text d-flex align-items-center ps-0 ps-md-3'>Vyhledávač nabídne pouze záměry, ke kterým máte přístup</Col>}
                        </Row>
                        {error && inputValue?.trim() === '' && (
                            <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                                <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />Bez vyplnění pole “Záměr” není možné pokračovat.</p>
                            </Col>
                        )}

                        {hasError && inputValue?.trim() !== '' && !isValidName && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                            <p className='d-flex align-items-center p-0 warning-text'>
                                <img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                Vyhledejte a vyberte existující záměr.
                            </p>
                        </Col>}
                    </Row>}

                {zamerType === 'new' &&
                    <Row className='row-wrapper'>
                        <p className='title p-0 mb-2' style={{ color: hasError ? '#C52A3A' : 'black' }}>{title}</p>
                        <label className='mb-2 px-0'>
                            <Row className=''>
                                <Col xs={12} md={6} className='d-flex flex-column'>
                                    <input
                                        type='text'
                                        required
                                        disabled={isDocumentationWithId}
                                        defaultValue={isDocumentationWithId ? decodedToken?.intentionFileNumber + ' ' + decodedToken?.intentionName : request.buildIntention.title?.value ?? ''}
                                        style={{ border: hasError && 'solid 1px #C52A3A' }}
                                        onBlur={(e) => {
                                            setRequest(state => ({ ...state, buildIntention: { ...state.buildIntention, title: { ...state.buildIntention.title, value: e.target.value } } }));
                                            if (!e.target.value?.trim()?.length || e.target.value?.trim()?.length > 100) {
                                                setFocused(true);
                                            }
                                        }}
                                        className={`id-input2 ${!isDocumentationWithId && 'bg-white'} p-2`}
                                        onChange={(e) => {
                                            if (focused && e.target.value?.trim()?.length && e.target.value?.trim()?.length <= 100) {
                                                setFocused(false);
                                            }
                                        }}
                                    />
                                </Col>
                            </Row>
                        </label>
                        {hasError && <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 3 }} className='mt-2'>
                            <p className='d-flex align-items-center p-0 warning-text'><img src={errorIcon} alt='warning' className='me-2' style={{ height: '1.1rem', width: '1.1rem' }} />
                                {isEmpty && 'Bez vyplnění pole “Záměr” není možné pokračovat.'}
                                {isLimitExceeded && `Název záměru může mít nejvýše ${String(limit)} znaků. Prosím zkraťte text.`}
                            </p>
                        </Col>}
                    </Row>}
            </Container>

            {isForm10 && <Row className='row-wrapper'>
                <p className='d-flex align-items-start p-0 mb-2 existing-zamer-info'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    Tento formulář návrhu neslouží pro stanovení ochranného pásma k ochraně okolí před negativními účinky stavby, které se stanovují z moci úřední</p>
                <p className='d-flex align-items-start p-0 existing-zamer-info'>
                    <img src={infoCircle} alt="infoCircle" className='me-2' />
                    V případě stanovení ochranného pásma, které je součástí záměru, pro který se žádá o povolení stavby a které se netýká blíže neurčeného okruhu osob nebo se netýká ochranného pásma vodního díla, se podává návrh na stanovení ochranného pásma jako součást žádosti o povolení stavby na formuláři, který je obsažen v příloze č. 3 vyhlášky č. 149/2024 Sb.</p>
            </Row>}

        </>
    );
};

ZamerSelectionControls.propTypes = {
    title: PropTypes.string,
    textExistujici: PropTypes.string,
    textNovy: PropTypes.string,
    state: PropTypes.object,
    setState: PropTypes.func,
    setRequest: PropTypes.func,
    setParcelAndConstructionData: PropTypes.func,
    setNewBuilding: PropTypes.func,
    buildIntentionModel: PropTypes.object,
    request: PropTypes.object,
    isIntentionCheckboxDisabled: PropTypes.bool,
    isDocumentationWithId: PropTypes.any,
};

export default ZamerSelectionControls;
