import { Col, Container, Row } from 'react-bootstrap';
import React, { useContext, useState, useEffect, useImperativeHandle } from 'react';
import axios from 'axios';
import { AuthContext } from 'react-oauth2-code-pkce';
import FormContext from '../../formContexts/FormContext';
import PropTypes from 'prop-types';
import { formSave, getFormById } from '../../apiCalls/formApiCalls';
import StavbyTable from '../Forms/ZamerComponentTables/StavbyTable.jsx';
import { spreadParcelsAndConstructions, shouldNotSave, spreadObject, filterOutConstructionsAndParcels } from '../../helperFunctions/helpers.js';
import { /* errorIcon, */ infoCircle } from '../../assets/index.js';
import usePrevious from '../CustomHooks/usePrevious.jsx';
import useFirstRender from '../CustomHooks/useFirstRender.jsx';
import { useLocation, useNavigate } from 'react-router-dom';

const StavbaComponent14 = ({ 

    urlPath
}) => {
    const {intention, stepValue, validationRef, setStagesArr, /* stagesArr, */ saveFormRef, id} = useContext(FormContext);
    const { token } = useContext(AuthContext);
    
    // const error = stagesArr.find(stg => Number(stg.stage) === Number(stepValue))?.error ?? false;
    // const [ focused, setFocused ] = React.useState({
    //     nazevStavby: false,
    //     obec: false,
    // });
    const [request, setRequest] = useState({
        'applicationId': id,
        buildIntention: {
            buildingName: '',
            address: {
                city: '',
                cityPart: '',
                street: '',
                descNum: '',
                orientNum: '',
                zip: '',
            },
        }
    });
    const prevRequest= usePrevious();
    const firstRender = useFirstRender();
    const buildIntention =  request?.buildIntention;
    const [parcelAndConstructionData, setParcelAndConstructionData] = useState({
        approvedConstructions: [],
    });
    const [isExceeded, setIsExceeded] = useState(false);

    const { pathname } = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!id) {
            return;
        }
        const source = axios.CancelToken.source();
        (async () => {
            const response = await getFormById(id, token, source, intention, null, pathname, navigate);
            if ((200 <= response?.status) && (response?.status < 300)) {
                spreadParcelsAndConstructions(
                    response.data, //obj
                    setParcelAndConstructionData, //func
                    parcelAndConstructionData // obj                
                );

                const loadedData = response?.data?.buildIntention;

                if (loadedData) {
                    const rest = filterOutConstructionsAndParcels(loadedData);
                    setRequest(state => ({ ...state, buildIntention: spreadObject(rest) }));
                }               
            } else {
                console.log('ID NOT YET IN DB');
            }
        })();

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, []);

    const saveChanges = async () => {
        const source = axios.CancelToken.source();
        await formSave(request, token, urlPath, source);
    };

    useEffect(() => {
        if (request?.buildIntention?.buildConstructions && parcelAndConstructionData.buildConstructions.length === 0) {
            setParcelAndConstructionData(prev => ({
                ...prev,
                buildConstructions: prev?.buildConstructions?.length === 0 ? [...(request?.buildIntention?.buildConstructions ?? [])] : prev.buildConstructions,
            }));
        }

        if (shouldNotSave(prevRequest, request, firstRender)) {
            return;
        }
        saveChanges();
    }, [request.buildIntention]);

    // const handleFocused = (elementToFocus) => {
    //     setFocused(prev => ({...prev, [elementToFocus]: true}));
    // };

    useImperativeHandle(saveFormRef, () => ({
        saveForm: () => saveChanges()
    }));

    useImperativeHandle(validationRef, () => ({
        validate() {
            let err = false;
    
            // if (!buildIntention?.address?.city?.trim()) {
            //     err = true;
            // }
            // else if (!buildIntention?.address?.cityPart?.trim()) {
            //     err = true;
            // }

            setStagesArr(prev => [...prev].map(stg => {
                if (Number(stg.stage) === Number(stepValue)) {
                    
                    return {...stg, error: err ?? false, };
                }
                return stg;
            }));
    
            
    
            return !err;
        }
    }));

    return (
        <Container fluid>
            <Row className='row-wrapper'>
                <h2 className='p-0 m-0 main-title'>{`${stepValue}. Stavba`}</h2>
            </Row>
            <Row className='row-wrapper mb-1'>
                <p className='p-0 pb-4 section-title'>Základní údaje o stavbě, pro kterou se požaduje změna v užívání</p>
            </Row>
            {/* <Col md={6}>
                <label className='p-0 mb-4 povoleni-label' style={{color: (!buildIntention?.buildingName?.trim() && (error || focused.nazevStavby)) ? '#C52A3A' : ''}}>
                    Název stavby
                    <input 
                        type='text'
                        required
                        {...(focused.nazevStavby && { focused: 'true' })}
                        defaultValue={buildIntention?.buildingName}
                        style={{border: error && !buildIntention?.buildingName?.trim() ? '#C52A3A solid 1px' : ''}}
                        onBlur={(e) => { 
                            setRequest(prev => ({ ...prev, buildIntention: {...prev.buildIntention, buildingName: e.target.value}}));
                            handleFocused('nazevStavby');
                        }}
                        className=' id-input2 bg-white p-2 mt-1'
                    />
                    {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                        Bez vyplnění pole &quot;Název stavby&quot; není možné pokračovat.
                    </Container>}
                    {
                        error
                        &&
                        !buildIntention?.buildingName?.trim()
                        &&
                        <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                            <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                            Bez vyplnění pole &quot;Název stavby&quot; není možné pokračovat.
                        </Container>
                    }
                </label>
            </Col> */}

            <Row className='border p-4 row-wrapper'>
                <h3 className='p-0 id-title'>Místo stavby</h3>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='p-0 povoleni-label' /* style={{color: error && !buildIntention?.address?.city?.trim() || (focused.obec && !buildIntention?.address?.city?.trim()) ? '#C52A3A' : ''}} */>
                        Obec (nepovinné)
                            <input
                                type='text'
                                // required
                                // {...(focused.obec && { focused: 'true' })}
                                defaultValue={buildIntention?.address?.city}
                                // style={{border: error && !buildIntention?.address?.city?.trim() ? '#C52A3A solid 1px' : ''}}
                                onBlur={(e) => {
                                    setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention?.address, city: e.target.value}}}));
                                    // handleFocused('obec');
                                }}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                            {/* {!error && <Container className='input-message--error' style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                            </Container>}
                            {
                                error
                                &&
                                !buildIntention?.address?.city?.trim()
                                &&
                                <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.5rem'}}>
                                    <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                                    Bez vyplnění pole &quot;Obec&quot; není možné pokračovat.
                                </Container>
                            } */}
                        </label>
                    </Col>
                </Row>
                <Row className='d-flex pb-4'>
                    <Col md={6}>
                        <label className='select-input'>
                        Část obce (nepovinné)
                            <input type='text' defaultValue={request?.buildIntention?.address?.cityPart}
                                onBlur={(e) => {setRequest(prev => ({...prev, buildIntention: {...prev.buildIntention, address: {...prev.buildIntention.address, cityPart: e.target.value}}}));}}
                                className='mt-1 id-input2 waterworks bg-white p-2'
                            />
                        </label>
                    </Col>
                </Row>
            </Row>
            <Row className='row-wrapper'>
                <StavbyTable
                    title={'Stavby'}

                    
                    urlPath={urlPath}
                    dataToAdd={parcelAndConstructionData.approvedConstructions}
                    approvedConstructions={true}
                    setIsExceeded={setIsExceeded}
                    setDataToAdd={setParcelAndConstructionData}
                />
                {isExceeded && <p className='d-flex align-items-start p-0 mt-3 stavba-info__text'> <img src={infoCircle} alt="infoCircle" className='me-2' />V jedné žádosti je možné zadat nejvýše 7 staveb.</p>}
            </Row>
        </Container>
    );
};

StavbaComponent14.propTypes = {

    urlPath: PropTypes.string.isRequired,
    setCheckbox: PropTypes.func,
};

export default StavbaComponent14;

