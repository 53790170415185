import React, { useEffect } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { GovLoading } from '@gov-design-system-ce/react';
import { redirectToErrorPage, setOAuthLocalStorage } from './utils.js';
import { ERROR_CODE_PARAM_NAME, TOKEN_PARAM_NAME } from './constants.js';

const UrednikLogin = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const token = searchParams.get(TOKEN_PARAM_NAME);
    const errorCode = searchParams.get(ERROR_CODE_PARAM_NAME) || '401';

    useEffect(() => {
        // Not token provided - return
        if (!token) {
            return redirectToErrorPage(errorCode, navigate);
        }

        try {
            const decodedToken = jwtDecode(token);

            setOAuthLocalStorage(decodedToken, token, false, null);

            window.location.replace('/');
        } catch (error) {
            // Not valid token - navigate to LandingPage for not authenticated user
            console.log(error);
            return redirectToErrorPage(errorCode, navigate);
        }
    }, [token, errorCode]);

    return <GovLoading message='Loading...' />;
};

export default UrednikLogin;
