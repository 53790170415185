import PropTypes from 'prop-types';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { validateCisloPopisne } from '../helperFunctions/helpers';
import { errorIcon } from '../assets';

const PropertyDeveloperAddress = ({
    buildIntention,
    setRequest,
    isCisloPopisnyError,
    setIsCisloPopisnyError
}) => {
    // const [ hasMounted, setHasMounted ] = React.useState(false);
    // let descNum = buildIntention?.propertyDeveloperDetails?.address?.descNum;
    // React.useMemo(() => {
    //     validateCisloPopisne(descNum, setIsCisloPopisnyError);
    // }, [descNum, hasMounted]);

    // React.useEffect(() => {
    //     setHasMounted(true);
    // }, []);

    return (
        <Row className='border p-4 row-wrapper'>
            <h3 className='p-0 id-title'>{buildIntention?.propertyDeveloperDetails?.isFOBusiness ? 'Místo podnikání' : 'Sídlo'}</h3>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label className='p-0 povoleni-label'>
                        Obec
                        <input 
                            type='text'
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.city ?? ''}
                            onBlur={(e) => { 
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, city: e.target.value}}  }})); 
                            }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label className='p-0 povoleni-label'>
                        Část obce
                        <input 
                            type='text'
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.cityPart ?? ''}
                            onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, cityPart: e.target.value}}  }})); }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label className='p-0 povoleni-label'>
                        Ulice
                        <input 
                            type='text' 
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.street ?? ''}
                            onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, street: e.target.value}}  }})); }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label
                        className='p-0 povoleni-label'
                        style={{color: isCisloPopisnyError ? '#C52A3A' : ''}}
                    >
                        Číslo popisné
                        <input
                            type='text' 
                            style={{border: isCisloPopisnyError ? 'solid #C52A3A 1px' : ''}}
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.descNum ?? ''}
                            onBlur={(e) => {validateCisloPopisne(e.target.value, setIsCisloPopisnyError); setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, descNum: e.target.value}}  }})); }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
                <Col md={6}>
                    <p className='mt-md-4 mt-3 building-change ps-md-3'>
                    Pokud je místo čísla popisného přiděleno číslo evidenční, uveďte před číslem písmeno E.
                    </p>
                </Col>
                {
                    isCisloPopisnyError &&
                    <Container style={{color: '#C52A3A', fontSize: '0.751rem', marginTop: '.1rem'}}>
                        <img src={errorIcon} alt='error icon' style={{marginRight: '.5rem', height: '1.1rem', width: '1.1rem'}}/>
                        Nesprávný formát vyplnění, zkuste to znovu.
                    </Container>
                }
            </Row>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label className='p-0 povoleni-label'>
                        Číslo orientační
                        <input 
                            type='text' 
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.orientNum ?? ''}
                            onBlur={(e) => { setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, orientNum: e.target.value}}  }})); }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
            </Row>
            <Row className='d-flex pb-4'>
                <Col md={6}>
                    <label className='p-0 povoleni-label'>
                        PSČ
                        <input 
                            type='text'
                            defaultValue={buildIntention?.propertyDeveloperDetails?.address?.zip ?? ''}
                            onBlur={(e) => {
                                setRequest(prev => ({ ...prev, buildIntention: { ...prev.buildIntention, propertyDeveloperDetails: {...prev.buildIntention?.propertyDeveloperDetails, address: {...prev.buildIntention?.propertyDeveloperDetails?.address, zip: e.target.value}}  }}));
                            }}
                            className='mt-1 id-input2'
                        />
                    </label>
                </Col>
            </Row>
        </Row>
    );
};

PropertyDeveloperAddress.propTypes = {
    setRequest: PropTypes.func,
    buildIntention: PropTypes.object,
};

export default PropertyDeveloperAddress;